import { component } from 'picoapp';

export default component((node) => {
  const SHOWCLASS = 'toast--is-visible';
  const duration = parseInt(node.getAttribute('data-duration')) || 3000;
  let timeoutId = null;

  //listen to the event that triggers the opening of a flash message
  node.addEventListener('showToast', function(){
    showMessage();
  });

  function showMessage() {
    node.classList.add(SHOWCLASS);

    if( duration > 0 ) {
      timeoutId = setTimeout(() => {
        hideMessage();
      }, duration);
    }
  }

  function hideMessage() {
    node.classList.remove(SHOWCLASS);
    clearTimeout(timeoutId);
    timeoutId = null;
  }
});