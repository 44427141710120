/**
 * A helper function that passes the provided value to the provided function.
 * If an array is provided, the function will be fired for each item in the
 * array.
 *
 * @param  {*}          x a value or array of values
 * @param  {function}   fn the function to call
 * @return {null}
 */
export function call(x, fn) {
  if (Array.isArray(x)) {
    for (let i = 0; i < x.length; i++) fn(x[i]);
  } else {
    return fn(x);
  }
}

/**
 * A factory function that creates an event listener util
 *
 * @param  {string}     action 'add' or 'remove'
 * @return {function}
 */
function events(action) {
  return (x, e, fn) => call(x, (x) => x[`${action}EventListener`](e, fn));
}

/**
 * Add an event listener to one elements or an array of elements.
 * Conveniently returns unsubscribe function.
 *
 * @param  {*}           x an element or an array of elements
 * @param  {string}      ev event type
 * @param  {function}    cb callback function
 * @return {function}
 */
export function on(x, ev, cb) {
  events('add')(x, ev, cb);
  return () => events('remove')(x, ev, cb);
}

/**
 * Get dimensions of viewport
 *
 * @return {object}
 */
export function size() {
  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  };
}