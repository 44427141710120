import { component } from 'picoapp';
import gsap from 'gsap';

export default component((node) => {
  const feTurb = document.getElementById('water-filter');
  
  const tl = gsap.timeline({
    repeat: -1,
    yoyo: true
  });

  tl.to(feTurb, {
    duration: 40,
    onUpdate: function() {
      const bfx = this.progress() * 0.005 + 0.020;
      const bfy = this.progress() * 0.05 + 0.1;
      const bfStr = bfx.toString() + ' ' + bfy.toString();

      feTurb.setAttribute('baseFrequency', bfStr);
    }
  }, 0);
});