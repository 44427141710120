import { component } from 'picoapp';
import idleTimeout from 'idle-timeout';

export default component((node) => {
  const form = document.querySelectorAll('.js-survey')[0];
  const instance = idleTimeout(
    () => {
      onTimeOut();
    },
    {
      element: document,
      timeout: 1000 * 3,
      loop: false
    }
  );
  const toast = document.getElementsByClassName('js-toast');
  const message = toast[0].querySelector('p');
  const text = {
    timeout: 'Out of time. Please try again.', 
    submit: 'Your response has been recorded. Thank you for your time.'
  };
  const btn = form.querySelector('.btn');

  // pause by default
  pauseTimer();

  // start the idle time out if the form is clicked
  form.addEventListener('click', () => {
    startTimer();
  });

  // send a message when submitted
  form.addEventListener('submit', logSubmit);

  function startTimer() {
    instance.resume();
    toggleBtn( true );
  }

  function pauseTimer() {
    instance.pause();
    toggleBtn( false );
  }

  function showToast(el, msg) {
    const event = new CustomEvent('showToast');
  
    el.dispatchEvent(event);
    message.textContent = msg;
    toggleBtn( false );
  }

  // callback for when we've been idle for longer than the timeout
  function onTimeOut() {
    const msg = text.timeout;

    form.reset();
    showToast(toast[0], msg);

    // reset the idle timer
    instance.reset();
    pauseTimer();
  }

  function toggleBtn( bool ) {
    if( bool ) {
      btn.removeAttribute('disabled');
    } else {
      btn.setAttribute('disabled', '');
    }
  }

  function logSubmit(e) {
    const msg = text.submit;

    e.preventDefault();
    showToast(toast[0], msg);
    instance.pause();
    form.reset();
  }
});

// https://dev.to/omerlahav/submit-a-form-to-a-google-spreadsheet-1bia