import { component } from 'picoapp';
import { on } from '../helpers/dom';

export default component((node, ctx) => {
  let sentinel;
  const SCALECLASS = 'scale-down';
  const header = document.querySelector('.js-site-head');

  const createSentinel = () => {
    const el = document.createElement('div');

    el.setAttribute('aria-hidden', 'true');
    node.parentElement.insertBefore(el, node);
    sentinel = node.previousElementSibling;
    sentinel.style.opacity = '0';
    sentinel.style.height = '1px';
  };

  const handleObserverIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.remove(SCALECLASS);
      } else {
        header.classList.add(SCALECLASS);
        // ctx.emit('header:toggle');
        // console.log('header:toggle');
      }
    });
  };

  // create a sentinel element to observe
  createSentinel();

  // create an observer
  const observer = new IntersectionObserver(handleObserverIntersection);

  // observe the sentinel
  observer.observe(sentinel);
});