import { component } from 'picoapp';

export default component((node, ctx) => {
  ctx.on('tick', ({ windowHeight }) => {
    const percentScrolled = node.scrollTop / (node.firstElementChild.offsetHeight - windowHeight);

    ctx.hydrate({ percentScrolled });
  });

  return () => {
    console.log('thanks Brian');
  };
});