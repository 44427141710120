import { component } from 'picoapp';
import idleTimeout from 'idle-timeout';

export default component((node, ctx) => {
  const Flickity = require('flickity');

  const el = node.querySelector('.slider__container');
  const flkty = new Flickity( el, {
    pageDots: false,
    wrapAround: true,
    percentPosition: false,
    imagesLoaded: true,
    autoPlay: 2000,
    // prevNextButtons: false,
    pauseAutoPlayOnHover: false
  });

  const instance = idleTimeout(
    () => {
      onTimeOut();
    },
    {
      element: el,
      timeout: 1000 * 5,
      loop: false
    }
  );

  function onTimeOut() {
    flkty.playPlayer();
  }
});