import { component } from 'picoapp';
import Zooming from 'zooming';

export default component(() => {
  const zooming = new Zooming({
    // bgOpacity: 0.8,
    // scaleBase: 0.7,
    scrollThreshold: 50,
    zIndex: -1,
    enableGrab: false
  });

  zooming.listen('.js-image-zoom');
});