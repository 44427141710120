import { component } from 'picoapp';
import gsap from 'gsap';

export default component((node) => {
  gsap.set('svg', {
    autoAlpha: 1
  });

  const dogTl = gsap.timeline({
    repeat: -1
  });
  const skullTl = gsap.timeline({
    repeat: -1
  });

  dogTl.from('#lp-text-1', {
    attr: { startOffset: '110%' },
    duration: 5, 
    ease: 'none',
    stagger: 5
  });

  dogTl.to('#lp-text-1', {
    autoAlpha:0, 
    duration: 0.5, 
    stagger:5
  }, 5);

  skullTl.from('#lp-text-2', {
    attr: { startOffset: '110%' },
    duration: 15, 
    ease: 'none',
    stagger: 15
  });

  skullTl.to('#lp-text-2', {
    autoAlpha:0, 
    duration: 0.5, 
    stagger:15
  }, 15);
});