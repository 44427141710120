import { picoapp } from 'picoapp';
import { size } from './helpers/dom';

import scrollPosition from './components/scrollPosition';
import redaction from './components/redaction';
import headScale from './components/headScale';
import water from './components/water';
import textPath from './components/textPath';
import accordion from './components/accordion';
import timer from './components/timer';
import toast from './components/toast';
import slider from './components/slider';
import table from './components/table';
import zoom from './components/zoom';

const components = {
  scrollPosition,
  redaction,
  headScale,
  water,
  textPath,
  accordion,
  timer,
  toast,
  slider,
  table,
  zoom
};

const state = {
  ...size(),
  isHeaderExpanded: false,
  percentScrolled: 0,
};

export default picoapp(components, state);