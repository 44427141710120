import { component } from 'picoapp';

export default component((node) => {
  const survey = node.querySelectorAll('.survey')[0];
  const surveyLoadedClass = 'survey--loaded';
  const surveyExpandedLayoutClass = 'survey--expanded';
  const customEvent = new CustomEvent('update-survey');
  let resizingId = false;

  function initSurvey() {
    // checkSurveyLayout();
    survey.classList.add(surveyLoadedClass);

    // custom event emitted when window is resized
    survey.addEventListener('update-survey', () => {
      checkSurveyLayout();
    });
  }

  function checkSurveyLayout() {
    const layout = getComputedStyle(survey, ':before').getPropertyValue('content').replace(/\'|"/g, '');
    
    survey.classList.toggle(surveyExpandedLayoutClass, layout != 'collapsed');
  }

  const beforeContent = getComputedStyle(survey, ':before').getPropertyValue('content');

  if(beforeContent && beforeContent !='' && beforeContent !='none') {
    initSurvey();
  } else {
    survey.classList.add(surveyLoadedClass);
  }

  window.addEventListener('resize', () => {
    clearTimeout(resizingId);
    resizingId = setTimeout(doneResizing, 300);
  });

  function doneResizing() {
    survey.dispatchEvent(customEvent);
  }

  (window.requestAnimationFrame) ? window.requestAnimationFrame(doneResizing) : doneResizing();
});