import { component } from 'picoapp';

export default component((node) => {
  const original = node.textContent;
  const splitOnLetter = node.innerText.split('');
  const redacted = splitOnLetter.map((letter, i) => {
    // things go weird when the line wraps and
    // spaces collapse if any kind of block display is attributed.
    // ...so make sure spaces between words remain inline.
    const maybeInline = letter === ' ' ? 'inline' : 'inline-block';

    return `<span class="word letter-${i}" style="display: ${maybeInline}" aria-hidden="true">${letter}</span>`;
  }).join('');

  const setIntensity = splitOnLetter < 2 ? '-0.38em' : '-0.6ex';

  node.style.setProperty('--redaction-intensity', setIntensity);
  node.setAttribute('aria-label', `${original} - [redacted]`);
  node.innerHTML = redacted;
});